import React from 'react';
import Layout from '../components/Layout';
import ProseModule from '../components/ProseModule';
import SimpleHeaderModule from '../components/SimpleHeaderModule';

export default () => (
  <Layout pageTitle="Miter Privacy Policy Version 0.8 - December 2021" description="Miter's Privacy Policy">
    <SimpleHeaderModule title="Privacy Policy">Version 0.8 - December 2021</SimpleHeaderModule>
    <ProseModule>
      <p>
        At Miter, we respect your privacy. I know, I know...that's just what someone who didn't respect your privacy
        would say. So let's get specific. We want to earn and keep your trust. We want to treat your data as we'd have
        you treat ours. And at the same time, we want to use your data respectfully to improve your experience and that
        of others in our product. Because we're such an early-stage company, that does mean members of our team looking
        at user data periodically. We will work to do so with respect and care, and appreciate the trust you place in us
        the help you're giving us.
      </p>

      <h2>Data We Collect</h2>
      <p>
        This policy covers our prerelease products, prior to a full "v1.0" launch. It does not pertain to the miter.co
        website. Here's what we collect today or plan to collect in the near future:
      </p>
      <ul role="list">
        <li>Basic account information for accounts you link with Miter, e.g., Google or Zoom.</li>
        <li>Your contact information from those accounts:&nbsp;name, email address, profile picture.</li>
        <li>Data available in your calendar:&nbsp;event titles, descriptions, invitees, times, and so on.</li>
        <li>
          Data available in meetings in your videoconference platform: meeting title, participant information, and so
          on.
        </li>
        <li>
          Information entered directly into our product(s), whether embedded in another tool (e.g., Chrome extension or
          Zoom app) or explicitly in a Miter product.
        </li>
        <li>Behavioral data as provided by an analytics platform like Heap or Google Analytics.</li>
      </ul>
      <p>
        We use a number of third-party services to collect and store data, for internal communication and data storage,
        and to operate our services, e.g., Heap, Google Analytics, Slack, Google Docs, Heroku, and others. We make
        reasonable efforts to ensure these are secure and reputable, we use encrypted channels to transmit data over the
        internet, and we take additional precautions with non-aggregated user data.
      </p>

      <h2>What We Do With It</h2>
      <p>We use the data we collect as follows:</p>
      <ul role="list">
        <li>
          To operate the services we provide to you. That is, your Google account may be used to access your calendars
          on your behalf. Your calendar data may be used so we can show you your meetings. And so on.{' '}
        </li>
        <li>
          To learn about what's working and what's not, and to improve. That means observing and analyzing data
          directly, something we do with care—in particular, avoiding situations where those outside our team could
          observe that data.
        </li>
      </ul>
      <h2>What We Won't Do With It</h2>
      <p>
        Again, our company is young and evolving fast. The ways we work with data will do the same and, as our products
        mature, our processes around data will do so as well. Broadly, that's good for you. Here's what won't change:
      </p>
      <ul role="list">
        <li>We will not sell your personal data to others, nor will we give it away except as outlined above.</li>
        <li>
          While some of our services will involve sending communications directly or on your behalf to people in your
          address book, we will not "spam" them: we'll endeavor to communicate in ways that are respectful to you, your
          contacts, and our other users. Our rule of thumb will be: "Would this person feel they've given us at least
          implicit permission to do this?"
        </li>
        <li>
          We will protect your data against unauthorized access—whether external (e.g., hacking) or internal (e.g.,
          inappropriate use by employees for personal gain). Cybersecurity is tricky business so there are no guarantees
          here, from anyone—but we will make the effort.
        </li>
      </ul>
      <p>‍</p>
      <p>
        <em>Questions? </em>
        <a href="/contact">
          <em>Contact us.</em>
        </a>
      </p>
    </ProseModule>
  </Layout>
);
